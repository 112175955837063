@import "scss/variables";

.ModalCarousel-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: $recon-white;
  padding-left: 29px;
  padding-right: 34px;
  z-index: 100000;

  &-close {
    border: none !important;
    color: $recon-white;
    background-color: transparent !important;
  }

  &-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $vdp-menu-item-color;

    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
    }

    &-subtitle {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  }

  &-more {
    border: none !important;
    color: $recon-white;
    background-color: transparent !important;
  }

  &-context-menu {
    position: absolute;
    background-color: #fff;
    right: 75px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 8px 22px;
    box-shadow: 0 8px 8px rgb(38 50 56 / 32%);
    border-radius: 3px;

    &-item {
      border: none !important;
      background-color: #fff;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: $recon-background-secondary;
      text-transform: capitalize !important;
      padding-top: 8px;
      padding-bottom: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &-icon {
        margin-right: 12px !important;
      }
    }
  }
}

.ModalCarousel-nav-button {
  background-color: #000;
  border: 0;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  height: 40px;
  outline: 0;
  position: fixed;
  top: 50%;
  width: 40px;
}

.ModalCarousel-nav-button-previous {
  left: 20px;
}

.ModalCarousel-nav-button-next {
  right: 20px;
}

.ModalCarousel-syndication-modal.modal {
  z-index: 50000 !important;
}

.ModalCarousel-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-image {
    height: auto;
    max-height: 100vh;
    max-width: 100vh;
    user-select: none;
  }

  &-attachment {
    height: 85vh;
    width: 85vw;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: white;
      width: 225px;
      height: 275px;

      svg {
        font-size: 150px;
      }

      .MuiButtonBase-root {
        font-weight: 700;
      }
    }
  }
}
