@import "scss/variables";
@import "scss/theme/variables";

.mobile-invoice {
  padding: 0;
  flex: 1;

  .invoice-page {
    min-height: auto;
    width: auto;
    padding: 16px;

    .horizontal-line::after {
      content: " ";
      display: block;
      border-top: 2px solid $recon-grey;
      border-bottom: 1px solid $recon-grey;
      border-radius: 8px;
    }

    .horizontal-line-margin-top::after {
      margin-top: 8px;
    }

    .horizontal-line-margin-bottom::after {
      margin-bottom: 4px;
    }

    .invoice-header {
      display: flex;
      flex-direction: column;

      .invoice-number-input {
        padding-top: 8px;
        padding-bottom: 16px;
        width: 190px;
      }

      .invoice-number-container {
        display: flex;
        padding-top: 16px;

        .invoice-number-label {
          font-size: 14px;
        }

        .invoice-number {
          font-size: 14px;
          font-weight: 600;
          padding-left: 0;
          margin-left: 4px;
        }
      }

      .purchase-order-number-input {
        width: 190px;
      }

      .purchase-order-number-container {
        display: flex;

        .purchase-order-number-label {
          font-size: 14px;
        }

        .purchase-order-number {
          font-size: 14px;
          font-weight: 600;
          padding-left: 0;
          margin-left: 4px;
        }
      }

      .vehicle,
      .vin-total {
        flex-direction: column;

        .total {
          justify-content: flex-start;
        }
      }

      .header-top {
        display: flex;
        flex-direction: row;
        padding-top: 8px;

        .header-left {
          .vendor-logo-container {
            display: flex;

            .vendor-logo {
              height: 60px;
            }
          }
        }

        .header-right {
          flex: 1;
          padding-left: 16px;
        }
      }

      .header-details {
        .invoice-tenant-name {
          font-size: 16px;
          font-weight: 600;
        }

        .invoice-tenant-detail {
          font-size: 16px;
        }
      }

      .invoice-status {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        border-radius: 4px;
        align-self: center;
        margin-bottom: 4px;

        &.paid {
          color: $danger;
          border: 2px solid $danger;
          width: 90px;
        }

        &.invoiced {
          color: $info;
          border: 2px solid $info;
          width: 90px;
        }
      }
    }

    .invoice-dealer-details {
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      padding: 10px;
      margin-top: 16px;
      margin-bottom: 16px;

      .invoice-dealer-details-label {
        color: $recon-grey;
        font-size: 16px;
      }

      .invoice-dealer-details-value {
        font-size: 16px;
      }

      .invoice-dealer-details-amount {
        font-weight: 600;
        font-size: 20px;
      }

      .invoice-dealer-details-total {
        display: flex;
        flex-direction: column;
      }

      .invoice-dealer-hr {
        margin-top: 8px;
        border-top: 2px solid #d5d5d5;
      }

      .invoice-dealer-details-row {
        padding-top: 8px;
        display: flex;
        flex-direction: row;
      }

      .invoice-dealer-center {
        flex: 1;
        display: flex;
        justify-content: center;
      }

      .tenant-details-container {
        display: flex;
        flex-direction: column;

        .tenant-detail {
          font-size: 16px;
        }

        .tenant-detail-bold {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .invoice-main {
      flex: none;

      .item {
        margin-bottom: 24px;

        .vehicle {
          flex-direction: column;
          background-color: $recon-dark-grey;
          padding: 5px 14px;
          display: flex;
          flex: 1;
          border-radius: 4px;

          .year-make-model {
            flex: 1;
            color: $light;
          }

          .vin {
            flex: 1;
            color: $light;
          }

          .stock {
            flex: 1;
            color: $light;
          }
        }

        .task {
          .task-item {
            display: flex;
            flex-direction: row;
            flex: 1;
            font-size: 14px;
            color: $recon-grey;
            padding-top: 8px;
            padding-bottom: 4px;
          }

          .task-row {
            display: flex;
            flex-direction: row;

            .task-full {
              flex: 1;
              padding-right: 16px;
            }

            .task-value {
              display: flex;
              flex: 1;
              flex: auto;
              font-size: 15px;
              color: $recon-black;
              padding-left: 4px;
            }

            .task-total {
              font-weight: 600;
              justify-content: flex-end;
              padding-right: 4px;
              align-self: center;
            }

            .task-vehicle-total {
              align-self: center;
              padding-right: 4px;
              padding-top: 6px;
              padding-bottom: 4px;
            }
          }

          .task-notes {
            color: $recon-dark-grey;
            padding-left: 4px;
            padding-right: 54px;
            font-size: 14px;
          }

          .task-value-background {
            margin-bottom: 2px;
            background-color: $recon-hover;
            border-radius: 4px;
            padding: 8px;
          }
        }
      }
    }

    .invoice-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .total-price {
        font-size: 18px;
        font-weight: 600;
        padding-right: 4px;
      }
    }
  }
}
