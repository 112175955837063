@import "scss/variables";
@import "scss/theme/variables";

.VehicleCardNotes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .VehicleCardNotes-header {
    color: $recon-black;
    font-weight: $recon-medium;
    padding: $spacing-sm 0;
  }

  .VehicleCardNotes-notes {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden auto;
    overflow-wrap: anywhere;
    margin-bottom: auto;

    .VehicleCardNotes-emptyNotes {
      margin: $spacing-xl;
      align-self: center;
      text-align: center;
    }

    .VehicleCardNotes-notesByDate {
      display: flex;
      flex-direction: column;
      padding: $spacing-sm 0;
      margin-right: $spacing-md;

      .VehicleCardNotes-date {
        align-self: center;
        color: $recon-grey;
      }

      .VehicleCardNotes-note {
        display: flex;
        flex-direction: column;

        .VehicleCardNotes-message {
          a {
            display: inline-block;
            width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: top;
            white-space: nowrap;
          }
        }

        .VehicleCardNotes-senderTime {
          display: flex;
          align-items: center;
          margin-top: $spacing-sm;

          .VehicleCardNotes-sender {
            color: $recon-black;
            font-weight: $recon-medium;
          }

          .VehicleCardNotes-time {
            margin-left: $spacing-md;
            color: $recon-grey;
          }
        }

        .VehicleCardNotes-messageDropdown {
          display: flex;
        }

        .VehicleCardNotes-attachment {
          display: flex;
          margin-left: $spacing-md;
        }

        .dropright {
          margin-left: auto;
          padding-left: $spacing-sm;
          opacity: 0.6;
          transition: 0.5s opacity;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
