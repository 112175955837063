@import "../../../../scss/variables";

.DealerStepReportV3 {
  --card-shadow: 0 4px 20px rgb(0 0 0 / 25%);

  padding: 0 20px;

  .DealerStepReport-title {
    font-weight: $recon-medium;
    font-size: 24px;
    line-height: 28px;
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 101px;
    letter-spacing: 0.15px;
  }

  .DealerStepReport-Overview {
    --border-radius: 5px;

    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: $spacing-sm;

    .SummaryCard {
      border-radius: var(--border-radius);
      box-shadow: var(--card-shadow);
      display: flex;
      flex: 1 0 auto;
    }

    .SummaryCard-neutral,
    .SummaryCard-base-stat-label-text,
    .SummaryCard-goal-label-text,
    .SummaryCard-goal-value-text {
      color: $recon-white;
    }

    .SummaryCard-base-stat-label-container.hasGoal {
      padding-bottom: 15px;
    }

    .SummaryCard-goal-label-text {
      font-size: 14px;
    }

    .SummaryCard-base-stat-label-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 1px;
    }

    .SummaryCard-goal-value-text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }

    .SummaryCard-base-stat-value-text {
      font-weight: 600;
      font-size: 50px;
      line-height: 55px;
      letter-spacing: 0.5px;
    }

    .SummaryCard-divider {
      margin: 0 15px;
      border-top: 2px solid rgb(255 255 255 / 55%);
    }

    .SummaryCard-goal-container {
      padding: 2px 15px 7px;
    }

    .SummaryCard-base-stat-value-icon {
      color: $recon-white;
    }

    .SummaryCard-base-stat-container {
      padding-top: 33px;
    }

    .MuiSvgIcon-root {
      opacity: 0.7;
    }

    .DealerStepReport-Overview-Items {
      display: flex;
      justify-content: space-between;
      gap: 15px;

      .DealerStepReport-Overview-Item {
        width: 100%;
        display: flex;

        > *,
        .SummaryCard {
          display: flex;
          flex: 1 0;
        }

        .DealerStepReport-Overview-CompletedVehicles > .SummaryCard {
          background-color: #263238;

          .SummaryCard-base-stat-container {
            padding: 0;
          }
        }

        .DealerStepReport-Overview-Item-gray > .SummaryCard {
          background-color: #263238;
        }

        .DealerStepReport-Overview-Item-good > .SummaryCard,
        .DealerStepReport-Overview-Item-neutral > .SummaryCard {
          background-color: #139538;
        }

        .DealerStepReport-Overview-Item-bad > .SummaryCard {
          background-color: #d50000;
        }
      }

      .DealerStepReport-Overview-QuadrantCard {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 10px 10px;

        .DealerStepReport-Overview-Item-Quadrant {
          border-radius: var(--border-radius);
          box-shadow: var(--card-shadow);
          background-color: #0084ce;
          color: $recon-white;
          text-align: center;
          display: grid;
          place-items: center;
          align-content: center;
          padding: 10px;

          h4 {
            font-size: 20px;
            color: $recon-white;
            margin: 0;
            margin-top: 5px;
          }

          h6 {
            font-size: 15px;
            color: $recon-white;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .DealerStepReport-StepSummary {
    display: flex;
    flex-direction: column;
    width: 100%;

    .DealerStepReport-StepSummary-table {
      --border-radius: 5px;

      font-size: 22px;
      line-height: 20px;
      letter-spacing: 0.25px;
      box-shadow: var(--card-shadow);
      border-radius: var(--border-radius);
    }

    .StickyContainer {
      border-radius: var(--border-radius) var(--border-radius) 0 0;

      .DealerStepReport-StepSummary-summary-row-header {
        transition: border-radius 100ms;
        border-radius: var(--border-radius) var(--border-radius) 0 0;
      }

      &.stuck .DealerStepReport-StepSummary-summary-row-header {
        border-radius: 0;
      }
    }

    .DealerStepReport-StepSummary-summary-row-header {
      padding: 15px 36px;
      display: flex;
      width: 100%;
      background-color: #a9a9a9;
      color: $recon-white;
      font-weight: $recon-medium;

      .evenly-spaced-container {
        width: 100%;
        gap: $spacing-lg;
      }

      .evenly-spaced-item {
        display: flex;
        align-items: center;
        justify-content: center;

        &.step {
          justify-content: flex-start;
        }
      }
    }

    .DealerStepReport-StepSummary-summary-body {
      border-radius: 0 0 var(--border-radius) var(--border-radius);

      > :nth-child(odd) {
        background-color: $recon-white;
      }

      > :nth-child(even) {
        background-color: #e4e4e4;
      }

      .StepAccordion > button {
        all: unset;
        display: block;
        width: 100%;
        cursor: pointer;
      }

      .DealerStepReport-StepSummary-summary-row {
        display: flex;
        align-items: center;
        min-height: 50px;
        padding: $spacing-sm $spacing-md;
        box-sizing: border-box;
        font-weight: 400;
        border-bottom: 1px solid #e4e4e4;

        .ArrowIcon {
          transition: transform 250ms;
          color: rgb(0 0 0 / 54%);
        }

        &.AccordionOpen .ArrowIcon {
          transform: rotate(90deg);
        }

        .DealerStepReport-StepSummary-stepWrapper {
          display: flex;
          padding: $spacing-sm;
          padding-left: $spacing-sm + $spacing-slg;
        }

        &.HasArrow .DealerStepReport-StepSummary-stepWrapper {
          padding-left: $spacing-sm;
        }

        .DealerStepReport-StepSummary-columnsWrapper {
          align-items: center;
        }

        .DealerStepReport-StepSummary-vehicles {
          text-decoration: underline;
        }

        .DealerStepReport-StepSummary-AvgRetailReadyTime,
        .DealerStepReport-StepSummary-AvgReconTime {
          &.withGoal {
            font-size: 18px;
          }

          &.early {
            color: #1ab245;
          }

          &.overdue {
            color: #d50000;
          }

          + .goal {
            display: block;
            font-size: 16px;
            color: #808080;
          }
        }
      }

      .DealerStepReport-StepSummary-summary-body-ChildSteps {
        font-size: 18px;

        > :nth-child(odd) {
          background-color: $recon-white;

          &:last-child {
            border-bottom: 1px solid #e4e4e4;
          }
        }

        > :nth-child(even) {
          background-color: #f3f3f3;
        }

        .DealerStepReport-StepSummary-summary-row {
          border-bottom: none;
          min-height: 35px;

          .DealerStepReport-StepSummary-AvgRetailReadyTime,
          .DealerStepReport-StepSummary-AvgReconTime {
            + .goal {
              display: inline;
            }
          }
        }
      }
    }
  }
}
